import { AxiosResponse } from 'axios'
import { Stream } from 'node:stream'
import { GET_ORDERS, GET_ORDER_TABLE_ROWS } from '../redux/actionTypes'
import { OrdersByStateCount, OrderTableRow } from '../redux/reducers/orders'
import { Order } from '../redux/reducers/portfolio'
import { Security } from '../redux/reducers/securities'
import { fetchRequest } from '../services/http.service'
import { CodeList } from '../types/common'

//TODO split/rename instrumentApi not suitable

export const GET_SECURITY_URL = '/security/'
export const GET_SECURITIES_URL = '/security'
export const GET_SECURITIES_BY_PARAM_URL = '/security/param?param='
export const GET_ORDER_URL = '/order/'
export const GET_ORDERS_URL = '/order'
export const POST_ORDER_URL = '/order/create'
export const GET_CODE_LIST_URL = '/codelist'
export const POST_MASS_ORDER_URL = '/order/create/mass'
export const GET_ORDERS_BY_STATE_COUNT_URL = '/order/count'
export const GET_ORDER_TABLE_ROWS_URL = '/order/byparams'
export const UPDATE_ORDER_URL = '/order/update'
export const EXPORT_ORDERS_URL = '/order/export'
export const IMPORT_FILE_URL = '/import'
export const DELETE_ORDER_URL = '/order/delete'

export const getSecurityRequest = (securityId: Number) =>
  fetchRequest.get<Security>(GET_SECURITY_URL + `${securityId}`, securityId)
export const getSecuritiesRequest = () => fetchRequest.get<Security[]>(GET_SECURITIES_URL)

export const getCodeListRequest = () => fetchRequest.get<CodeList[]>(GET_CODE_LIST_URL)

export const getOrderRequest = (orderId: Number) => fetchRequest.get<Order>(GET_ORDER_URL + `${orderId}`, orderId)
export const getOrdersRequest = () => fetchRequest.get<Order[]>(GET_ORDERS_URL)
export const postOrderRequest = (order: Order, portfolioId: Number | null) =>
  fetchRequest.post<Order>(POST_ORDER_URL, order, order.portfolio.portfolioId)
export const postMassOrderRequest = (massOrder: Order[]) => fetchRequest.post<Order[]>(POST_MASS_ORDER_URL, massOrder)
export const getOrdersByStateCountRequest = () => fetchRequest.get<OrdersByStateCount[]>(GET_ORDERS_BY_STATE_COUNT_URL)
export const getOrderTableRowsRequest = (state: string) =>
  fetchRequest.get<OrderTableRow[]>(GET_ORDER_TABLE_ROWS_URL, state)
export const updateOrderRequest = (order: Order) => fetchRequest.put<Order>(UPDATE_ORDER_URL, order)
export const exportOrdersRequest = () => fetchRequest.get<any>(EXPORT_ORDERS_URL)
export const importRequest = (orders: any) => fetchRequest.post<any>(IMPORT_FILE_URL, orders)
export const getInstrumentsByParamRequest = (param: any) =>
  fetchRequest.get<Security[]>(GET_SECURITIES_BY_PARAM_URL + `${param}`, param)
export const deleteOrderRequest = (order: Order) => fetchRequest.remove(DELETE_ORDER_URL, order)
