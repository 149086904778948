export const ORDER_DIRECTION = 'ORDER_DIRECTION'
export const ORDER_TYPE = 'ORDER_TYPE'
export const ORDER_VALIDITY = 'ORDER_VALIDITY'
export const ORDER_MARKET_TYPE = 'ORDER_MARKET_TYPE'

export const ORDER_TYPE_QUANTITY = 'OT_QUANTITY'
export const ORDER_TYPE_AMOUNT = 'OT_AMOUNT'
export const ORDER_MARKET_TYPE_LIMIT = 'OMT_LIMIT'
export const ORDER_MARKET_TYPE_MARKET = 'OMT_MARKET'
export const ORDER_VALIDITY_DATE = 'OV_GTD'
