import { FormControlLabel, Grid, makeStyles, Paper, Switch, Typography } from '@material-ui/core'
import React, { useEffect, useLayoutEffect } from 'react'
import { ContentTitle } from '../components/common/ContentTitle'
import { ClientInformationDialog } from '../components/dialogs/ClientInformationDialog'
import { BasicLayout } from '../components/layouts/BasicLayout'
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark'
import MinimizeIcon from '@material-ui/icons/Minimize'
import { ColoredIconButton } from '../components/common/ColoredIconButton'
import { useParams } from 'react-router'
import { useSelector } from '../redux/hooks/useSelector'
import { useClientApi } from '../hooks/useClientApi'
import manS from '../assets/manS.jpg'
import { TransparentIconButton } from '../components/common/TransparentIconButton'
import { useDispatch } from 'react-redux'
import { openDialog } from '../redux/actions/dialogActions'
import { CLIENT_INFORMATION_DIALOG_ID } from '../constants/dialogConstants'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useRiskProfileColor } from '../hooks/useRiskProfileColor'
import { InstrumentTable } from '../components/ClientDetail/InstrumentTable'
import { ClientPortfolio } from '../components/ClientDetail/ClientPortfolio'
import { OrderInputDialog } from '../components/dialogs/OrderInputDialog'
import { useInstrumentApi } from '../hooks/useInstrumentApi'
import { usePortfolioApi } from '../hooks/usePortfolioApi'
import { ClientDetailOrderTable } from '../components/ClientDetail/ClientDetailOrderTable'
import { useCurrencyApi } from '../hooks/useCurrencyApi'
import { PortfolioEnhancedDetail } from '../components/ClientDetail/PortfolioEnhancedDetail'
import { filterPortfolioDetail } from '../redux/actions/filterActions'
import { PortfolioDonutChart } from '../components/ClientDetail/PortfolioDonutChart'
import NumberFormat from 'react-number-format'
import { PortfolioEnhancedDetailLegendHandler } from '../components/ClientDetail/PortfolioEnhancedDetailLegendHandler'
import { PortfolioEnhancedDetailTotalsHandler } from '../components/ClientDetail/PortfolioEnhancedDetailTotalsHandler'
import { PortfolioEnhancedDetailTotals } from '../components/ClientDetail/PortfolioEnhancedDetailTotals'
import { PortfolioDonutChartMini } from '../components/ClientDetail/PortfolioDonutChartMini'
import { OrderDetailDialog } from '../components/dialogs/OrderDetailDialog'
import { useLocation } from 'react-router-dom'
import { getClientAction } from '../redux/actions/clientActions'
import { emptyClient, emptyPortfolio } from '../constants/initialStates'
import { getPortfolioAction } from '../redux/actions/portfolioActions'
import { showPortfolioDetailAction } from '../redux/actions/userActions'
import { format } from 'date-fns'
import { FileImportDialog } from '../components/dialogs/FileImportDialog'
import { ActionAlert } from '../components/dialogs/ActionAlert'
import { ActionFailedAlert } from '../components/dialogs/ActionFailedAlert'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  separator: {
    flexGrow: 1,
  },
  clientBasicInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '15%',
  },
  clientAdvancedInfoContainer: {
    maxHeight: 'calc(100vh - 250px);',
    minHeight: 'calc(100vh - 250px);',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  clientPersonalInfoContainer: {
    display: 'flex',
    width: '80%',
    height: '100%',
    marginRight: theme.spacing(2),
  },
  clientCredentialsContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'row',
  },
  clientCredentials: {
    width: '60%',
  },
  clientQuestionnare: {
    width: '40%',
  },
  clientEssentials: {
    width: '25%',
    display: 'flex',
    flexDirection: 'row',
  },
  notes: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
  },
  infoWindowTopBar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '25px',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  border: {
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  instrumentsTitle: {
    marginLeft: theme.spacing(1),
  },
  manIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  clientPersonalInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  Button: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(-0.5),
    marginLeft: theme.spacing(1),
  },
  riskProfileBasic: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75px',
    minWidth: '75px',
    borderRadius: '50%',
  },
  notesTitle: {
    margin: theme.spacing(0.5),
  },
  note: {
    marginLeft: theme.spacing(1),
  },
  infoWindowContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  gridContainer: {
    height: '50%',
  },

  portfolioContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  infoWindowActions: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  infoWindowData: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  chartContainer: {
    height: '100%',
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
  },
  portfolioDetailContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  portfolioDetailTitle: {
    width: '100%',
    height: '10%',
  },
  portfolioDetailContent: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
  },

  donutChart: {
    height: '100%',
    width: '80%',
    display: 'flex',
    alignItems: 'center',
  },
  centerContainerFlex: { display: 'flex', flexDirection: 'column' },
  totalsContainer: {
    height: '100%',
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  fontSizeTotal: {
    fontSize: '14px',
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  boldText: {
    fontWeight: 'bold',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  flexDisplay: {
    display: 'flex',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(0.5),
  },
}))

export const ClientDetail = () => {
  const classes = useStyles()
  const { clientId } = useParams<{ clientId: string }>()
  const { getClient } = useClientApi()
  const { getRates } = useCurrencyApi()
  const client = useSelector(state => state.clients.client)
  const { deactivateClient } = useClientApi()
  const { getOrders } = useInstrumentApi()
  const { getPortfolio } = usePortfolioApi()
  const dispatch = useDispatch()
  const riskProfileColor = useRiskProfileColor(client)
  const showPortfolioDetail = useSelector(state => state.useractions.showPortfolioDetail)
  const portfolioDetailFilter = useSelector(state => state.filters.portfolioDetail)

  useEffect(() => {
    getClient(Number(clientId))
    getOrders()
    getRates('CZK')
    console.log('getClientCalledOnClientDetailLoadHook', clientId)
  }, [])

  useEffect(() => {
    if (client.portfolios[0]?.portfolioId && Array.isArray(client.portfolios)) {
      getPortfolio(client.portfolios[0]?.portfolioId)
    }
    console.log('onClientChangePortfolioGet', client.portfolios[0])
  }, [client])

  useEffect(() => {
    if (
      portfolioDetailFilter.class === false &&
      portfolioDetailFilter.currency === false &&
      portfolioDetailFilter.instrumentType === false
    ) {
      dispatch(filterPortfolioDetail('instrumentType', true))
    }
  }, [portfolioDetailFilter])

  useLayoutEffect(() => {
    return () => {
      dispatch(getClientAction(emptyClient))
      dispatch(getPortfolioAction(emptyPortfolio))
      dispatch(showPortfolioDetailAction(false))
    }
  }, [])

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(filterPortfolioDetail(event.target.name, event.target.checked))
  }

  if (!client) return null

  return (
    <BasicLayout
      contentTitle={
        <ContentTitle
          contentTitle="Detail klienta"
          actionButtons={
            <>
              <TransparentIconButton
                icon={<EditIcon />}
                size={'small'}
                className={classes.Button}
                onClick={() => {
                  dispatch(
                    openDialog(CLIENT_INFORMATION_DIALOG_ID, { dialogTitle: 'Úprava klienta', client, type: 'edit' })
                  )
                }}
              />
              <TransparentIconButton
                icon={<DeleteIcon />}
                size={'small'}
                className={classes.Button}
                onClick={() => {
                  deactivateClient(client)
                }}
              />
            </>
          }
        />
      }
      className={classes.centerContainerFlex}
    >
      <div className={classes.root}>
        <div className={classes.clientBasicInfoContainer}>
          {/* TODO Move to other component. */}
          <Paper className={classes.clientPersonalInfoContainer}>
            <div className={classes.clientEssentials}>
              <div className={classes.manIcon}>
                <img alt="" src={manS} width={50} />
              </div>
              <div className={classes.clientPersonalInfoColumn}>
                <Typography variant="h5" color="primary">
                  {client.firstName} {client.surname}
                </Typography>
                <Typography color="primary">
                  <NumberFormat value={client.telephoneNumber} displayType={'text'} format="### ### ### ###" />
                </Typography>
              </div>
            </div>
            {!showPortfolioDetail ? (
              <div className={classes.clientCredentialsContainer}>
                <Grid className={classes.clientCredentials} container spacing={0}>
                  <Grid item xs={6}>
                    <Typography>Datum narození:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{client.birthDate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Národnost:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{client.nationality}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Adresa:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {client.permanentAddress?.street} {client.permanentAddress?.town}{' '}
                      {client.permanentAddress?.zipCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Platnost OD:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {client.identityCardValidity ? format(new Date(client.identityCardValidity), 'dd/MM/yyyy') : ''}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.clientQuestionnare} container spacing={0}>
                  <Grid item xs={6}>
                    <Typography>ID protistran:</Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        J&T:
                      </Grid>
                      <Grid item xs={6}>
                        {client.clientCounterpartyId}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Investiční dotazník:</Typography>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <>
                <div className={classes.infoWindowActions}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={portfolioDetailFilter.instrumentType}
                        onChange={handleSwitchChange}
                        name="instrumentType"
                        color="primary"
                        size="small"
                      />
                    }
                    label="Typ instrumentu"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={portfolioDetailFilter.class}
                        onChange={handleSwitchChange}
                        name="class"
                        color="primary"
                        size="small"
                      />
                    }
                    label="Třídy aktiv"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={portfolioDetailFilter.currency}
                        onChange={handleSwitchChange}
                        name="currency"
                        color="primary"
                        size="small"
                      />
                    }
                    label="Měna"
                  />
                </div>
                <div className={classes.chartContainer}>
                  <PortfolioDonutChartMini />
                </div>
                <div className={classes.marginTop}>
                  <PortfolioEnhancedDetailLegendHandler />
                </div>
              </>
            )}
          </Paper>

          {!showPortfolioDetail ? (
            <Paper className={classes.notes}>
              <Typography className={classes.notesTitle}>Poznámky:</Typography>
              <div className={classes.note}>
                <Typography>{client.note}</Typography>
              </div>
            </Paper>
          ) : (
            <PortfolioEnhancedDetailTotals />
          )}

          {/* !showPortfolioDetail && (
            <div className={classes.riskProfileBasic + ' ' + riskProfileColor}>
              <Typography variant="h4" color="secondary">
                {client.riskProfile}
              </Typography>
            </div>
          ) */}
        </div>
        {!showPortfolioDetail ? (
          <Grid container spacing={0} className={classes.clientAdvancedInfoContainer}>
            <Grid item xs={12} className={classes.gridContainer}>
              <ClientPortfolio />
            </Grid>
            <Grid item xs={6} className={classes.gridContainer}>
              {/*TODO Make simple component out of this collapsible box (props: children, title) */}
              <Paper variant="outlined" className={classes.portfolioContainer}>
                <div className={classes.infoWindowTopBar}>
                  <BrandingWatermarkIcon />
                  <Typography className={classes.instrumentsTitle}>Instrumenty</Typography>
                  <span className={classes.separator} />
                  <ColoredIconButton size="small" icon={<MinimizeIcon fontSize="small" />} />
                </div>
                <InstrumentTable />
                {/*<ReactVirtualizedTable />*/}
              </Paper>
            </Grid>
            <Grid item xs={6} className={classes.gridContainer}>
              <Paper variant="outlined" className={classes.portfolioContainer}>
                <div className={classes.infoWindowTopBar}>
                  <BrandingWatermarkIcon />
                  <Typography className={classes.instrumentsTitle}>Pokyny</Typography>
                  <span className={classes.separator} />
                  <ColoredIconButton size="small" icon={<MinimizeIcon fontSize="small" />} />
                </div>
                <ClientDetailOrderTable />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <PortfolioEnhancedDetail />
        )}
      </div>

      <ClientInformationDialog />
      <OrderInputDialog client={client} />
      <OrderDetailDialog />
      <FileImportDialog />
      <ActionAlert />
      <ActionFailedAlert />
    </BasicLayout>
  )
}
