import React from 'react'
import { ReduxAction } from '../../types/common'
import { ADD_ORDER, GET_PORTFOLIO, SHOW_PORTFOLIO_DETAIL } from '../actionTypes'

export enum PortfolioType {
  Stock = 'Akcie',
  Bond = 'Dluhopisy',
  FinancialMarket = 'Finanční trhy',
  Other = 'Ostatní',
}

export interface PortfolioAsset {
  name: string
  ISIN: string
  class: PortfolioType
  instrumentType: string
  currency: string
  instrumentCurrencyTotal: number
  portfolioCurrencyTotal: number
  amount: number | undefined
}

export interface Instrument {
  assetClass: string
  currency: string
  instrumentId: number
  instrumentType: string
  isin: string | null
  name: string
}

export interface Position {
  amount: number
  dateFrom: string
  dateTo: string
  instrument: Instrument
  positionId: number
  quantity: number
  averagePurchasePrice: number
  profitLoss: number
  profitLossPercentage: number | null
  lastPrice: number
}

export interface Portfolio {
  positions: Position[]
  unfinishedOrders: Order[]
  amount: number | null
  currency: string | null
  name: string | null
  portfolioId: number | null
}

interface PortfolioState {
  portfolio: Portfolio
}

//TODO move order interface back to order, possibly create interface file?

export interface Order {
  amount: number | null
  attachments: number | null
  cpty: {
    brokerName: string
    marketName: string
    name: string
  }
  currency: string | null
  direction: string
  orderId: number | null
  orderIdent: number | null
  portfolio: {
    currency: string | null
    name: string | null
    portfolioId: Number | null
    firstIndicatorDate: null
    lastIndicatorDate: null
  }
  price: number | null
  quantity: number | null
  securityId: number | null
  state: string | null
  type: string
  validityDate: Date | null
  validityType: string
  marketType: string
  orderCreationDate: string | Date | null
}

const INITIAL_STATE: PortfolioState = {
  portfolio: {
    positions: [],
    unfinishedOrders: [],
    amount: null,
    currency: null,
    name: null,
    portfolioId: null,
  },
}

export const detailPortfolioState = (state: PortfolioState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_PORTFOLIO:
      return {
        ...state,
        portfolio: action.payload as Portfolio,
      }
    case ADD_ORDER:
      return {
        ...state,
        portfolio: { ...state.portfolio, unfinishedOrders: [...state.portfolio.unfinishedOrders, action.payload] },
      }
    default:
      return state
  }
}
