import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { Security } from '../../redux/reducers/securities'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { Order } from '../../redux/reducers/portfolio'
import { ColoredIconButton } from '../common/ColoredIconButton'
import { ORDER_DETAIL_DIALOG_ID } from '../../constants/dialogConstants'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../redux/actions/dialogActions'
import { useSelector } from '../../redux/hooks/useSelector'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles(theme => ({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  textColor: {
    color: theme.palette.primary.dark,
  },
  boldText: {
    fontWeight: 'bold',
  },
  separatorLeftSmall: {
    marginLeft: theme.spacing(0.4),
  },
  separator: {
    flexGrow: 1,
  },
  separatorLeft: {
    marginLeft: theme.spacing(1),
  },
  separatorLeftHuge: {
    marginLeft: theme.spacing(20),
  },
  separatorLeftLarge: {
    marginLeft: theme.spacing(5),
  },
  borderColorShare: {
    borderColor: theme.palette.error.main,
  },
  borderColorFcMarket: {
    borderColor: theme.palette.success.main,
  },
  borderColorBond: {
    borderColor: theme.palette.primary.main,
  },
  borderColorOther: {
    borderColor: theme.palette.grey[600],
  },
  textColorGreen: {
    color: theme.palette.success.main,
  },

  iconSize: {
    height: '18px',
    width: '30px',
    lineHeight: '15px',
    fontSize: '8px',
  },
  textEnd: {
    textAlign: 'end',
  },
  editButton: {
    marginLeft: '12px',
    height: '18px',
    width: '18px',
  },
}))

interface Props {
  order: Order
  securities: Security[]
}

export const ClientDetailOrderTableRow: React.FC<Props> = ({ order, securities }) => {
  const classes = useStyles()
  const security = securities.find(s => order.securityId === s.securityId)
  const instrumentIconType = useInstrumentIconType(security?.assetClass || null)
  const dispatch = useDispatch()
  const client = useSelector(state => state.clients.client)

  if (!security) return null
  return (
    <div className={classes.tableRow}>
      <Typography style={{ width: 100 }} className={`${classes.textColor} ${classes.separatorLeft} `}>
        {order.orderIdent}
      </Typography>
      <InstrumentIcon
        className={`${classes.iconSize} ${instrumentIconType} ${classes.separatorLeft}`}
        securityType={security.assetClass}
      />
      <Typography style={{ width: 300 }} className={`${classes.textColor} ${classes.separatorLeft} `}>
        {security.name}
      </Typography>
      <Typography
        style={{ width: 100 }}
        className={`${classes.textColor}  ${classes.separatorLeftHuge} ${classes.textEnd}`}
      >
        {security.market}
      </Typography>
      <Typography
        style={{ width: 100 }}
        className={`${classes.textColor}  ${classes.separatorLeftLarge} ${classes.textEnd}`}
      >
        {order.currency}
      </Typography>
      <ColoredIconButton
        className={classes.editButton}
        size="small"
        onClick={() =>
          dispatch(
            openDialog(ORDER_DETAIL_DIALOG_ID, {
              clientId: client.clientId,
              securityId: security.securityId,
              orderId: order.orderId,
            })
          )
        }
        icon={<EditIcon style={{ fontSize: '12px' }} />}
      />
    </div>
  )
}
