import React, { useEffect, useLayoutEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Grid, MenuItem, Paper, Typography } from '@material-ui/core'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { MASS_ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { ArrowSelect } from '../OrderInputDialog/ArrowSelect'
import { codeListToArrowSelect } from '../../utils/codeListToArrowSelect'
import {
  ORDER_DIRECTION,
  ORDER_MARKET_TYPE,
  ORDER_MARKET_TYPE_LIMIT,
  ORDER_MARKET_TYPE_MARKET,
  ORDER_TYPE,
  ORDER_TYPE_AMOUNT,
  ORDER_TYPE_QUANTITY,
  ORDER_VALIDITY,
  ORDER_VALIDITY_DATE,
} from '../../constants/codeLists'
import { Order } from '../../redux/reducers/portfolio'
import { newOrder } from '../../constants/initialStates'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { MassOrderClient } from '../../redux/reducers/clients'
import manS from '../../assets/manS.jpg'
import { DropZone } from '../common/DropZone'
import { Security } from '../../redux/reducers/securities'
import { TransparentIconButton } from '../common/TransparentIconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { ClearConfirmationAlert } from '../massOrderInputDialog/ClearConfirmationAlert'
import NumberFormat from 'react-number-format'
import { NumericInput } from '../common/NumericInput'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ActionFailedAlert } from './ActionFailedAlert'
import { useCurrencyApi } from '../../hooks/useCurrencyApi'
import { useInputDialogColor } from '../../hooks/useInputDialogColor'
import { useMassOrderCoveredColor } from '../../hooks/useMassOrderCoveredColor'
import { useTranslation } from 'react-i18next'
import { useStyles } from './MassOrderInputDialog.styles'
import { useExchangeRate } from '../../hooks/useExchangeRate'
import { ActionAlert } from './ActionAlert'
import { VirtualizedAutocomplete } from '../common/VirtualizedAutocomplete'
import { useZebraStyle } from '../../hooks/useZebraStyle'

// TODO utils
const clientFilter = createFilterOptions({
  limit: 50, //TODO virtualize component
  matchFrom: 'any',
  stringify: (option: MassOrderClient) =>
    `${option.surname} ${option.firstName} ${option.surname} ${option.telephoneNumber} ${option.personalNumber}`,
})

const securityFilter = createFilterOptions({
  limit: 50, //TODO virtualize component
  matchFrom: 'any',
  stringify: (option: Security) => `${option.name} ${option.isin} ${option.ticker}`,
})

export const MassOrderInputDialog = () => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const orderCoveredColor = useMassOrderCoveredColor()
  const getInputDialogColor = useInputDialogColor()
  const { getRates } = useCurrencyApi()
  const { postMassOrder, getCodeList, codeList } = useInstrumentApi()
  const { getSecurities, getOrdersByStateCount } = useInstrumentApi()

  const foundDialog = dialogs.find(dialog => dialog.id === 'MASS_ORDER_INPUT_DIALOG_ID')
  const open = !!foundDialog
  const clients = useSelector(state => state.clients.clients)
  const [order, setOrder] = useState<Order>(newOrder)
  const [massOrderClients, setMassOrderClients] = useState<MassOrderClient[]>([])
  const [autoClientInputValue, setAutoClientInputValue] = useState('')
  const [autoHack, setAutoHack] = useState<boolean>(true)
  const [massOrderSecurity, setMassOrderSecurity] = useState<Security | null>()
  const [autoSecurityValue, setAutoSecurityValue] = useState('')
  const securities = useSelector(state => state.securities.securities)
  const instrumentIconType = useInstrumentIconType(!massOrderSecurity ? null : massOrderSecurity.assetClass)
  const [alertOpen, setAlertOpen] = useState(false)
  const rates = useSelector(state => state.currency.rates)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const exchangeRate = useExchangeRate()
  const getBackgroundColor = useZebraStyle()

  useEffect(() => {
    getCodeList()
    getSecurities()
    getRates('CZK')
    console.log(rates, 'RATES')
  }, [])

  useEffect(() => {
    setMassOrderSecurity(undefined)
    setMassOrderClients([])
    setOrder(newOrder)
  }, [open])

  useEffect(() => {
    !massOrderSecurity
      ? setOrder({ ...order, securityId: 0 })
      : setOrder({ ...order, securityId: massOrderSecurity.securityId, price: massOrderSecurity.lastQuotePrice })
  }, [massOrderSecurity])

  useEffect(() => {
    submitDisabledSet()
  }, [massOrderClients])

  useEffect(() => {
    submitDisabledSet()
  }, [order.direction])

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(MASS_ORDER_INPUT_DIALOG_ID))
    }
  }, [])

  const handleClose = () => {
    dispatch(closeDialog(MASS_ORDER_INPUT_DIALOG_ID))
  }

  const handleSave = () => {
    const mappedOrders = massOrderClients.map((client: MassOrderClient) => {
      const newOrder: Order = {
        ...order,
        amount: client.massOrderByAmount,
        quantity: client.massOrderByQuantity,
        portfolio: client.portfolios[0],
        currency: client.massOrderCurrency,
        state: 'OS_NEW',
        validityDate: order.validityDate,
        cpty: {
          ...order.cpty,
          marketName: massOrderSecurity?.market || '',
          name: `${order.cpty.brokerName} - ${massOrderSecurity?.market}`,
        },
      }
      return newOrder
    })
    postMassOrder(mappedOrders)
  }

  const handleChangeOrder = (name: string) => (value: string | number) => {
    setOrder({ ...order, [name]: value })
  }

  const handleDateChange = (date: Date | null) => {
    setOrder({ ...order, validityDate: date })
  }

  const changeLimitPrice = (event: any) => {
    setOrder({ ...order, price: event.target.value })
  }

  const onClientInputChange = (client: MassOrderClient) => (event: any) => {
    if (!massOrderSecurity) return
    let quantity = 0
    let amount = 0
    if (order.type === ORDER_TYPE_QUANTITY) {
      if (client.massOrderCurrency === massOrderSecurity?.currency) {
        quantity = Number(event.target.value)
        amount = Math.round(((Number(quantity) || 0) * (order.price || 0) + Number.EPSILON) * 100) / 100
      } else {
        quantity = Number(event.target.value)
        amount = exchangeRate(
          massOrderSecurity?.currency,
          client.massOrderCurrency,
          Math.round(((Number(quantity) || 0) * (order.price || 0) + Number.EPSILON) * 100) / 100,
          rates
        )
      }
    }
    if (order.type === ORDER_TYPE_AMOUNT) {
      if (client.massOrderCurrency === massOrderSecurity?.currency) {
        amount = Number(event.target.value)
        quantity = Math.round(((Number(amount) || 0) / (order?.price || 0) + Number.EPSILON) * 1000000) / 1000000
      } else {
        amount = Number(event.target.value)
        quantity =
          Math.round(
            ((Number(exchangeRate(client.massOrderCurrency, massOrderSecurity?.currency, amount, rates)) || 0) /
              (order?.price || 0) +
              Number.EPSILON) *
              1000000
          ) / 1000000
      }
    }
    if (order.direction === 'OD_SELL') {
      setMassOrderClients(
        massOrderClients.map((c: MassOrderClient) => {
          if (c.clientId === client.clientId) {
            return {
              ...c,
              massOrderByAmount: amount,
              massOrderByQuantity: quantity,
              coverPercentage: 100,
              isCovered: true,
            }
          }
          return c
        })
      )
    } else {
      const coverPercentageCalc =
        ((client.portfolios[0].accountsBalance || 0) /
          (exchangeRate(client.massOrderCurrency, 'CZK', amount, rates) * 1.05)) *
        100
      setMassOrderClients(
        massOrderClients.map((c: MassOrderClient) => {
          if (c.clientId === client.clientId) {
            return {
              ...c,
              massOrderByAmount: amount,
              massOrderByQuantity: quantity,
              coverPercentage: coverPercentageCalc,
              isCovered: coverPercentageCalc >= 100,
            }
          }
          return c
        })
      )
    }
  }

  const cover = (coverPercentage: number) => {
    if (order.direction === 'OD_SELL') {
      return 100
    } else if (coverPercentage < 100 && coverPercentage > 0) {
      return coverPercentage
    } else if (coverPercentage >= 100) {
      return 100
    } else if (coverPercentage < 0) {
      return 0
    }
  }

  const submitDisabledSet = () => {
    if (order.direction === 'OD_SELL') {
      setSubmitDisabled(false)
      console.log('SUBMIT', submitDisabled)
    } else if (massOrderClients.length === 0) {
      return true
    } else {
      return massOrderClients.forEach((c: MassOrderClient) => {
        switch (c.isCovered) {
          case false:
            setSubmitDisabled(true)
            break
          default:
            setSubmitDisabled(false)
        }
      })
    }
  }

  const onClientCurrencyChange = (client: MassOrderClient) => (event: any) => {
    if (!massOrderSecurity) return
    setMassOrderClients(
      massOrderClients.map((c: MassOrderClient) => {
        if (c.clientId === client.clientId) {
          return {
            ...c,
            massOrderCurrency: event.target.value,
            massOrderByAmount: c.exchangedFromTo
              ? exchangeRate(massOrderSecurity.currency, event.target.value, c.massOrderByAmount || 0, rates)
              : exchangeRate(c.massOrderCurrency, event.target.value, c.massOrderByAmount || 0, rates),
            exchangedFromTo: !c.exchangedFromTo,
          }
        }
        return c
      })
    )
  }

  const onClientClearClick = (client: MassOrderClient) => () => {
    setMassOrderClients(massOrderClients.filter(c => c.clientId !== client.clientId))
  }

  const onSecurityClearClick = () => {
    setAlertOpen(true)
  }

  const clearSecurity = () => {
    setMassOrderSecurity(undefined)
    setAlertOpen(false)
    setMassOrderClients(
      massOrderClients.map((c: MassOrderClient) => {
        return { ...c, massOrderCurrency: null, massOrderByAmount: null, massOrderByQuantity: null }
      })
    )
    setOrder({ ...order, price: 0, securityId: null })
  }

  const clearSecurityCancel = () => {
    setAlertOpen(false)
  }

  const totalQuantity = () => {
    let quantity = 0
    massOrderClients.map((c: MassOrderClient) => {
      quantity = quantity + (c.massOrderByQuantity || 0)
    })
    return quantity
  }

  const totalAmount = () => {
    if (!massOrderSecurity) return
    let amount = 0
    massOrderClients.map((c: MassOrderClient) => {
      amount = amount + exchangeRate(c.massOrderCurrency, massOrderSecurity?.currency, c.massOrderByAmount || 0, rates)
    })
    return amount
  }

  return (
    <div>
      <Dialog
        disableBackdropClick
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className={`${classes.clientInformationDialogHeader} ${getInputDialogColor(order.direction)}`}>
          <Typography variant="h5">Nový Hromadný Pokyn</Typography>
        </div>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className={classes.paperAndClearContainer}>
                <Paper className={classes.commodityContainer}>
                  {!massOrderSecurity ? (
                    <Autocomplete
                      className={classes.autoCompleteField}
                      onBlur={() => setAutoSecurityValue('')}
                      disableClearable
                      disableListWrap
                      options={securities}
                      renderOption={(props, option) => (
                        <div className={`${classes.flexColumn} ${classes.fullwidth}`}>
                          <span className={classes.boldText}>{props.name}</span>
                          <div className={`${classes.flexRow} ${classes.fullwidth}`}>
                            <span>{props.isin}</span>
                            <span className={classes.topLineSeparator} />
                            <span>{props.ticker}</span>
                          </div>
                        </div>
                      )}
                      filterOptions={securityFilter}
                      inputValue={autoSecurityValue}
                      id="controlled-demo"
                      onInputChange={(event: any) => {
                        if (!event?.target?.value) {
                          setAutoSecurityValue('')
                          return
                        }
                        setAutoSecurityValue(event.target.value)
                      }}
                      onChange={(event: any, newValue: Security | null) => {
                        if (!newValue) return
                        setAutoSecurityValue('')
                        setMassOrderSecurity(newValue)
                      }}
                      renderInput={params => <TextField {...params} label="Výběr instrumentu" margin="normal" />}
                    />
                  ) : (
                    <>
                      <div className={classes.topContainer}>
                        <InstrumentIcon
                          className={`${classes.iconSize} ${instrumentIconType} ${classes.position}`}
                          securityType={massOrderSecurity.assetClass}
                        />
                        <span className={`${classes.topLineText} ${classes.boldText} ${classes.textColor}`}>
                          {massOrderSecurity.name}
                        </span>
                        <div className={classes.topLineSeparator} />
                        <div className={classes.isin}>
                          <span className={` ${classes.boldText} ${classes.textColor}`}>{massOrderSecurity.isin}</span>
                          <span className={` ${classes.textColor}`}>{t(massOrderSecurity.instrumentType)}</span>
                        </div>
                      </div>
                      <div className={classes.bottomContainer}>
                        <div className={classes.flexColumn}>
                          <span className={`${classes.boldText} ${classes.textSeparatorLeft} ${classes.textColor}`}>
                            <NumberFormat
                              value={massOrderSecurity.lastQuotePrice}
                              displayType="text"
                              thousandSeparator=" "
                              decimalScale={8}
                              decimalSeparator=","
                            />{' '}
                            {massOrderSecurity.currency}
                          </span>
                          <span className={`${classes.securityInfo} ${classes.textSeparatorLeft}`}>
                            {massOrderSecurity.lastQuoteDate}
                          </span>
                        </div>
                        <span className={`${classes.percentage} ${classes.textSeparatorLeft}`}>
                          {massOrderSecurity.lastPriceIndication}
                        </span>
                        <div className={classes.topLineSeparator} />
                        <span className={classes.securityInfo}>
                          {massOrderSecurity.symbol}: {massOrderSecurity.market}
                        </span>
                      </div>
                    </>
                  )}
                </Paper>
                {massOrderSecurity && (
                  <TransparentIconButton
                    className={classes.clearButton}
                    icon={<ClearIcon fontSize="small" color="primary" />}
                    onClick={onSecurityClearClick}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.addClientContainer}>
                {/*DOUBLE RENDER NECCESSARY TO BYPASS INTERNAL ISSUE WITH AUTOCOMPLETE NOT TRIGGERING ONCHANGE WHEN NEW SELECTION MATCHES PREVIOUS ONE*/}
                {autoHack && (
                  <Autocomplete
                    disabled={!massOrderSecurity}
                    className={classes.autoCompleteField}
                    onBlur={() => setAutoClientInputValue('')}
                    disableClearable
                    fullWidth
                    options={clients}
                    getOptionLabel={(option: MassOrderClient) =>
                      `${option.surname} ${option.firstName}, ${option.personalNumber}`
                    }
                    filterOptions={clientFilter}
                    inputValue={autoClientInputValue}
                    id="controlled-demo"
                    onInputChange={(event: any) => {
                      if (!event?.target?.value) {
                        setAutoClientInputValue('')
                        return
                      }
                      setAutoClientInputValue(event.target.value)
                    }}
                    onChange={(event: any, newValue: MassOrderClient | null) => {
                      if (!newValue) {
                        return
                      }
                      setAutoHack(!autoHack)
                      setAutoClientInputValue('')
                      if (!massOrderClients.find(e => e.clientId === newValue.clientId) && massOrderSecurity) {
                        setMassOrderClients([
                          ...massOrderClients,
                          { ...newValue, massOrderCurrency: massOrderSecurity.currency, exchangedFromTo: true },
                        ])
                      }
                    }}
                    renderInput={params => <TextField {...params} label="Výběr klienta" margin="normal" />}
                    renderOption={(props, option) => (
                      <div className={`${classes.flexColumn} ${classes.fullwidth}`}>
                        <span className={classes.boldText}>
                          {props.firstName} {props.surname}
                        </span>
                        <div className={`${classes.flexRow} ${classes.fullwidth}`}>
                          <span>
                            <NumberFormat
                              value={Math.round(((props.portfolios[0].amount || 0) + Number.EPSILON) * 100) / 100}
                              displayType="text"
                              fixedDecimalScale
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator=" "
                            />{' '}
                            {props.portfolios[0].currency}
                          </span>
                          <span className={classes.topLineSeparator} />
                          <span>
                            <NumberFormat value={props.telephoneNumber} displayType={'text'} format="### ### ### ###" />
                          </span>
                        </div>
                      </div>
                    )}
                  />
                )}
                {!autoHack && (
                  <Autocomplete
                    disabled={!massOrderSecurity}
                    className={classes.autoCompleteField}
                    onBlur={() => setAutoClientInputValue('')}
                    disableClearable
                    fullWidth
                    options={clients}
                    getOptionLabel={(option: MassOrderClient) =>
                      `${option.surname} ${option.firstName}, ${option.personalNumber}`
                    }
                    filterOptions={clientFilter}
                    inputValue={autoClientInputValue}
                    id="controlled-demo"
                    onInputChange={(event: any) => {
                      if (!event?.target?.value) {
                        setAutoClientInputValue('')
                        return
                      }
                      setAutoClientInputValue(event.target.value)
                    }}
                    onChange={(event: any, newValue: MassOrderClient | null) => {
                      if (!newValue) {
                        return
                      }
                      setAutoHack(!autoHack)
                      setAutoClientInputValue('')
                      if (!massOrderClients.find(e => e.clientId === newValue.clientId) && massOrderSecurity) {
                        setMassOrderClients([
                          ...massOrderClients,
                          { ...newValue, massOrderCurrency: massOrderSecurity.currency, exchangedFromTo: true },
                        ])
                      }
                    }}
                    renderInput={params => <TextField {...params} label="Výběr klienta" margin="normal" />}
                    renderOption={(props, option) => (
                      <div className={`${classes.flexColumn} ${classes.fullwidth}`}>
                        <span className={classes.boldText}>
                          {props.firstName} {props.surname}
                        </span>
                        <div className={`${classes.flexRow} ${classes.fullwidth}`}>
                          <span>
                            <NumberFormat
                              value={Math.round(((props.portfolios[0].amount || 0) + Number.EPSILON) * 100) / 100}
                              displayType="text"
                              fixedDecimalScale
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator=" "
                            />{' '}
                            {props.portfolios[0].currency}
                          </span>
                          <span className={classes.topLineSeparator} />
                          <span>
                            <NumberFormat value={props.telephoneNumber} displayType={'text'} format="### ### ### ###" />
                          </span>
                        </div>
                      </div>
                    )}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.massOrderTotals}>
                <div className={classes.totalsContainer}>
                  <div className={classes.totalsLabels}>
                    <div className={`${classes.textColor} ${classes.totalsLabel}`}>Celkový počet kusů:</div>
                    <div className={`${classes.textColor} ${classes.totalsLabel}`}>Celkový objem:</div>
                  </div>
                  <div className={classes.totalsNumbers}>
                    <div className={`${classes.totalsNumber} ${classes.boldText} ${classes.textColor}`}>
                      <NumberFormat
                        value={totalQuantity()}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={4}
                        decimalSeparator=","
                      />
                    </div>
                    <div className={`${classes.totalsNumber} ${classes.boldText} ${classes.textColor}`}>
                      {!massOrderSecurity ? (
                        0
                      ) : (
                        <NumberFormat
                          value={totalAmount()}
                          displayType="text"
                          thousandSeparator=" "
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          suffix={`${!massOrderSecurity ? ' ' : ` ${massOrderSecurity.currency}`}`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Paper>
              <Paper className={classes.selectionContainer}>
                <ArrowSelect
                  value={t(order.marketType)}
                  label="Typ pokynu"
                  options={codeListToArrowSelect(ORDER_MARKET_TYPE, codeList)}
                  onChange={handleChangeOrder('marketType')}
                />
                {order.marketType === ORDER_MARKET_TYPE_MARKET && (
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth={true}
                    value={!massOrderSecurity ? 0 : massOrderSecurity?.lastQuotePrice}
                    label="Cena"
                  />
                )}
                {order.marketType === ORDER_MARKET_TYPE_LIMIT && (
                  <NumericInput fullWidth={true} value={order.price || ''} onChange={changeLimitPrice} label="Cena" />
                )}
                <ArrowSelect
                  value={t(order.direction)}
                  label="Směr pokynu"
                  options={codeListToArrowSelect(ORDER_DIRECTION, codeList)}
                  onChange={handleChangeOrder('direction')}
                />
                <ArrowSelect
                  value={t(order.type)}
                  label="Způsob pokynu"
                  options={codeListToArrowSelect(ORDER_TYPE, codeList)}
                  onChange={handleChangeOrder('type')}
                />
                <ArrowSelect
                  value={t(order.validityType)}
                  label="Platnost pokynu"
                  options={codeListToArrowSelect(ORDER_VALIDITY, codeList)}
                  onChange={handleChangeOrder('validityType')}
                />
                {order.validityType === ORDER_VALIDITY_DATE && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      helperText={false}
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Datum"
                      value={order.validityDate || new Date()}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
                {/*}   <ArrowSelect value={textFieldValue} label="Protistrana" options={textFieldValues} /> {*/}
              </Paper>
            </Grid>
            <Grid item xs={6} className={classes.massOrderClientsContainer}>
              {massOrderClients.map((client: MassOrderClient, index) => (
                <div key={index} className={classes.paperAndClearContainer}>
                  <Paper
                    variant="outlined"
                    className={`${classes.clientContainer} ${orderCoveredColor(
                      client.coverPercentage ? client.coverPercentage >= 100 : false
                    )}`}
                  >
                    <div>
                      <div className={classes.clientCredentialsContainer}>
                        <img className={classes.clientIcon} src={manS} width={20} />
                        <div className={classes.clientCredentials}>
                          <span className={classes.boldText}>{client.surname}</span>
                          <span className={classes.smallFont}>{client.firstName}</span>
                          <NumberFormat
                            className={classes.smallFont}
                            value={client.telephoneNumber}
                            displayType={'text'}
                            format="##### ### ### ###"
                          />
                        </div>
                      </div>
                      <div className={classes.amountInputContainer}>
                        {order.type === ORDER_TYPE_AMOUNT && (
                          <NumericInput
                            fullWidth={true}
                            value={client.massOrderByAmount || ''}
                            onChange={onClientInputChange(client)}
                            label="Objem obchodu"
                          />
                        )}
                        {order.type === ORDER_TYPE_QUANTITY && (
                          <NumericInput
                            fullWidth={true}
                            value={client.massOrderByQuantity || ''}
                            onChange={onClientInputChange(client)}
                            label="Počet kusů"
                          />
                        )}
                      </div>
                    </div>
                    <div className={classes.currencySelection}>
                      <TextField
                        fullWidth
                        id="standard-select-currency"
                        select
                        label="Měna"
                        value={client.massOrderCurrency}
                        onChange={onClientCurrencyChange(client)}
                      >
                        <MenuItem key={client.portfolios[0].currency} value={client.portfolios[0].currency}>
                          {client.portfolios[0].currency}
                        </MenuItem>
                        <MenuItem key={massOrderSecurity?.currency} value={massOrderSecurity?.currency}>
                          {massOrderSecurity?.currency}
                        </MenuItem>
                      </TextField>
                    </div>
                    <div className={classes.clientOrderTotalContainer}>
                      {order.type === ORDER_TYPE_AMOUNT && (
                        <>
                          <div>Počet kusů</div>
                          <div className={classes.boldText}>
                            <NumberFormat
                              value={client.massOrderByQuantity || 0}
                              displayType="text"
                              thousandSeparator=" "
                              fixedDecimalScale
                              decimalScale={4}
                              decimalSeparator=","
                            />
                          </div>
                        </>
                      )}
                      {order.type === ORDER_TYPE_QUANTITY && (
                        <>
                          <div>Objem</div>
                          <div className={classes.boldText}>
                            <NumberFormat
                              value={client.massOrderByAmount}
                              displayType="text"
                              thousandSeparator=" "
                              fixedDecimalScale
                              decimalScale={2}
                              decimalSeparator=","
                            />{' '}
                            {client.massOrderCurrency || massOrderSecurity?.currency}
                          </div>
                        </>
                      )}
                      <div className={`${classes.centerContent} ${classes.flexColumn}`}>
                        <div className={`${classes.smallFont}`}>Požadované krytí</div>
                        <div className={`${classes.smallFont}`}>
                          {/*<NumberFormat
                            value={exchangeRate(
                              order.currency,
                              client.portfolios[0].currency,
                              client.massOrderByAmount || 0,
                              rates
                            )}
                            displayType="text"
                            thousandSeparator=" "
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            />*/}
                          N/A
                        </div>
                      </div>
                    </div>
                    <div className={`${classes.portfolioTotalsContainer} ${classes.centerContent}`}>
                      <div className={classes.centerContent}>
                        <div>Krytí</div>
                        <div className={classes.boldText}>
                          <NumberFormat
                            value={cover(client.coverPercentage ? client.coverPercentage : 0)}
                            displayType="text"
                            thousandSeparator=" "
                            fixedDecimalScale
                            decimalScale={0}
                            decimalSeparator=","
                          />
                          %
                        </div>
                      </div>
                      <span className={`${classes.smallFont}`}>Zůstatek</span>
                      <span className={`${classes.smallFont}`}>
                        <NumberFormat
                          value={client.portfolios[0].accountsBalance}
                          displayType="text"
                          thousandSeparator=" "
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                        />{' '}
                        {client.portfolios[0].currency}
                      </span>
                    </div>
                  </Paper>
                  <TransparentIconButton
                    className={classes.clearButton}
                    icon={<ClearIcon fontSize="small" color="primary" />}
                    onClick={onClientClearClick(client)}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Storno
          </Button>
          <Button
            className={`${classes.actionButtons} ${getInputDialogColor(order.direction)}`}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {order.direction === 'OD_BUY' ? 'Nakoupit' : 'Prodat'}
          </Button>
        </DialogActions>
      </Dialog>
      <ClearConfirmationAlert
        alertOpen={alertOpen}
        onOk={clearSecurity}
        onCancel={clearSecurityCancel}
        alertText="odstranit instrument"
      />
      <ActionFailedAlert />
      <ActionAlert />
    </div>
  )
}
