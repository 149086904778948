import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'
import { useSelector } from '../redux/hooks/useSelector'
import { OrdersByStateOptions } from '../redux/reducers/filters'
import { OrderTableRow } from '../redux/reducers/orders'
import { Order } from '../redux/reducers/portfolio'
import { RootState } from '../redux/reducers/root'

export const useOrderFilters = () => {
  const orderTableRows: OrderTableRow[] = useSelector((state: RootState) => state.orders.orderTableRows)
  const filters = useSelector((state: RootState) => state.filters)

  let filteredTableRows = orderTableRows.filter(
    (order: OrderTableRow) =>
      `${order.clientFirstname}${order.clientSurname}`
        .toLowerCase()
        .includes(filters.searchedClientName.toLowerCase()) &&
      (order.amount || 0) >= filters.searchedAmountUp &&
      (order.amount || 0) <= (filters.searchedAmountDown === '' ? Infinity : filters.searchedAmountDown) &&
      `${order.securityName}`.toLowerCase().includes(filters.searchedInstrumentName.toLowerCase()) &&
      `${order.currency}`.toLowerCase().includes(filters.searchedCurrencyName.toLowerCase())
  )

  switch (filters.sortOrdersBy) {
    case OrdersByStateOptions.New:
      filteredTableRows = orderTableRows.filter(row => row.state === 'OS_NEW')
      break
    case OrdersByStateOptions.Open:
      filteredTableRows = orderTableRows.filter(row => row.state === 'OS_SENT')
      break
    case OrdersByStateOptions.Partial:
      filteredTableRows = orderTableRows.filter(row => row.state === 'OS_PARTIAL')
      break
    case OrdersByStateOptions.Rejected:
      filteredTableRows = orderTableRows.filter(row => row.state === 'OS_REJECTED')
      break
    case OrdersByStateOptions.Closed:
      filteredTableRows = orderTableRows.filter(row => row.state === 'OS_SETTLED')
  }
  return filteredTableRows
}
