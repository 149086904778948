import { newOrder } from '../../constants/initialStates'
import { ReduxAction } from '../../types/common'
import { GET_ORDER, GET_ORDERS, GET_ORDERS_BY_STATE_COUNT, GET_ORDER_TABLE_ROWS, UPDATE_ORDER } from '../actionTypes'
import { Order } from './portfolio'

export interface OrdersByStateCount {
  count: number
  state: string
}

export interface OrderTableRow {
  amount: number
  clientFirstname: string
  clientId: number
  clientSurname: string
  currency: string
  direction: string
  orderId: number
  orderIdent: string
  securityId: number
  securityName: string
  instrumentType: string
  state: string
  orderCreationDate: Date
  validityType: string
  validityDate: string
  price: number
  quantity: number
  isin: string
  symbol: string
  ticker: string
  orderMarketType: string
  assetClass: string
}
interface OrderState {
  orders: Order[]
  ordersByStateCount: OrdersByStateCount[]
  orderTableRows: OrderTableRow[]
  order: Order
}

const INITIAL_STATE = {
  orders: [],
  ordersByStateCount: [],
  orderTableRows: [],
  order: newOrder,
}

export const orders = (state: OrderState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload as Order[],
      }
    case GET_ORDERS_BY_STATE_COUNT:
      return {
        ...state,
        ordersByStateCount: action.payload as OrdersByStateCount[],
      }
    case GET_ORDER_TABLE_ROWS:
      return {
        ...state,
        orderTableRows: action.payload as OrderTableRow[],
      }
    case GET_ORDER:
      return {
        ...state,
        order: action.payload as Order,
      }
    case UPDATE_ORDER:
      return {
        ...state,
        order: action.payload as Order,
      }
    default:
      return state
  }
}
