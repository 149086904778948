import React, { useEffect, useLayoutEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { useSelector } from '../../redux/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../redux/actions/dialogActions'
import { ORDER_EDIT_DIALOG_ID } from '../../constants/dialogConstants'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { ArrowSelect } from '../OrderInputDialog/ArrowSelect'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import {
  ORDER_DIRECTION,
  ORDER_MARKET_TYPE,
  ORDER_MARKET_TYPE_LIMIT,
  ORDER_MARKET_TYPE_MARKET,
  ORDER_TYPE,
  ORDER_TYPE_AMOUNT,
  ORDER_TYPE_QUANTITY,
  ORDER_VALIDITY,
  ORDER_VALIDITY_DATE,
} from '../../constants/codeLists'
import { codeListToArrowSelect } from '../../utils/codeListToArrowSelect'
import manS from '../../assets/manS.jpg'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import { Order } from '../../redux/reducers/portfolio'
import NumberFormat from 'react-number-format'
import { Client } from '../../redux/reducers/clients'
import { NumericInput } from '../common/NumericInput'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  editDialog: {},
  clientInformationDialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  clientContainer: {
    height: '100px',
    width: '550px',
    display: 'flex',
    flexDirection: 'row',
  },
  fulfillContainer: {
    height: '100px',
    width: '550px',
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  commodityContainer: {
    height: '100px',
    width: '550px',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  selectionContainer: {
    width: '550px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  currencyTotal: {
    height: '50px',
    width: '550px',
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  clientPersonalInfoContainer: {
    height: '100%',
    width: '50%',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  clientPortfolioContainer: {
    height: '100%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  topContainer: {
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
  iconSize: {
    height: '25px',
    width: '35px',
    lineHeight: '25px',
    fontSize: '12px',
  },
  position: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  topLineText: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  topLineIsin: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  topLineSeparator: {
    flexGrow: 1,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    width: '100%',
    height: '50%',
    alignItems: 'flex-end',
  },
  securityInfo: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.success.main,
  },
  boldText: {
    fontWeight: 'bold',
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  clientIcon: {
    height: '40px',
    width: '40px',
  },
  clientCredentials: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  clientPhone: {
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    width: '50%',
  },
  alreadyFullfill: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dropZoneBorder: {
    border: `1px dashed ${theme.palette.grey[500]}`,
    height: '100px',
    width: '550px',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  halfWidth: {
    width: '50%',
  },
  totalsContainer: {
    height: '100%',
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const OrderEditDialog = () => {
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const dispatch = useDispatch()
  const { getCodeList, codeList, updateOrder, getOrderTableRows } = useInstrumentApi()
  const foundDialog = dialogs.find(dialog => dialog.id === 'ORDER_EDIT_DIALOG_ID')
  const open = !!foundDialog
  const orderToUpdate: Order = useSelector(state => state.orders.order)
  const [order, setOrder] = useState<Order>(orderToUpdate)
  const client = useSelector(state => state.clients.client)
  const security = useSelector(state => state.securities.security)
  const instrumentIconType = useInstrumentIconType(!security ? null : security?.assetClass)
  const selectedState = useSelector(state => state.filters.sortOrdersBy)
  const { t } = useTranslation()

  useEffect(() => {
    if (foundDialog) {
      getCodeList()
      setOrder(orderToUpdate)
    }
  }, [getCodeList, setOrder, orderToUpdate, foundDialog])

  useEffect(() => {
    getOrderTableRows(selectedState)
  }, [open])

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(ORDER_EDIT_DIALOG_ID))
      console.log('closeOrderEditOnLocationChangeFired')
    }
  }, [])

  const handleClose = () => {
    dispatch(closeDialog(ORDER_EDIT_DIALOG_ID))
  }

  const handleUpdate = () => {
    updateOrder(order)
  }

  const onClientInputChange = (client: Client) => (event: any) => {
    let quantity = 0
    let amount = 0
    if (order.type === ORDER_TYPE_QUANTITY) {
      quantity = event.target.value
      amount = Math.round(((Number(quantity) || 0) * (order?.price || 0) + Number.EPSILON) * 100) / 100
    }
    if (order.type === ORDER_TYPE_AMOUNT) {
      amount = event.target.value
      quantity = Math.round(((Number(amount) || 0) / (order?.price || 0) + Number.EPSILON) * 1000000) / 1000000
    }
    setOrder({ ...order, amount: amount, quantity: quantity })
  }

  const handleChangeArrowSelect = (name: string) => (value: string | number) => {
    setOrder({ ...order, [name]: value })
  }

  const handleChangeOrder = (name: string) => (event: any) => {
    setOrder({ ...order, [name]: event.target.value })
  }

  const handleDateChange = (date: Date | null) => {
    setOrder({ ...order, validityDate: date })
  }

  const handleTextFieldChangeOrder = (name: string) => (event: any) => {
    handleChangeOrder(name)(event.target.value)
  }

  if (!security || !codeList) return null

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.editDialog}
        maxWidth="lg"
      >
        <div className={classes.clientInformationDialogHeader}>
          <Typography variant="h5">Upravit Pokyn</Typography>
        </div>
        <DialogContent>
          <div className={classes.dialogContentContainer}>
            <div>
              <Paper variant="outlined" className={classes.clientContainer}>
                <div className={classes.clientPersonalInfoContainer}>
                  <img alt="" className={classes.clientIcon} src={manS} width={20} />
                  <div className={classes.clientCredentials}>
                    <span>
                      {client?.firstName} <span className={classes.boldText}>{client?.surname}</span>
                    </span>
                    <span className={classes.clientPhone}>
                      <NumberFormat value={client.telephoneNumber} displayType={'text'} format="##### ### ### ###" />
                    </span>
                  </div>
                </div>
                <div className={classes.clientPortfolioContainer}></div>
              </Paper>
              <Paper variant="outlined" className={classes.commodityContainer}>
                <div className={classes.topContainer}>
                  <InstrumentIcon
                    className={`${classes.iconSize} ${instrumentIconType} ${classes.position}`}
                    securityType={security.assetClass}
                  />
                  <span className={`${classes.topLineText} ${classes.boldText} ${classes.textColor}`}>
                    {security.name}
                  </span>
                  <div className={classes.topLineSeparator} />
                  <span className={`${classes.topLineIsin} ${classes.boldText} ${classes.textColor}`}>
                    {security.isin}
                  </span>
                </div>
                <div className={classes.bottomContainer}>
                  <span className={classes.securityInfo}>
                    {security.lastQuoteDate} <span className={classes.boldText}>{security.lastQuotePrice}</span>{' '}
                    {security.currency} <span className={classes.percentage}>{security.lastPriceIndication}</span>
                  </span>
                  <div className={classes.topLineSeparator} />
                  <span className={classes.securityInfo}>{security.market}</span>
                </div>
              </Paper>
              <Paper variant="outlined" className={classes.selectionContainer}>
                <ArrowSelect
                  value={t(order.marketType)}
                  label="Typ pokynu"
                  options={codeListToArrowSelect(ORDER_MARKET_TYPE, codeList)}
                  onChange={handleChangeArrowSelect('marketType')}
                />
                {order.marketType === ORDER_MARKET_TYPE_MARKET && (
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth={true}
                    value={security.lastQuotePrice}
                    label="Cena"
                  />
                )}
                {order.marketType === ORDER_MARKET_TYPE_LIMIT && (
                  <NumericInput
                    fullWidth={true}
                    value={order.price || ''}
                    onChange={handleChangeOrder('price')}
                    label="Cena"
                  />
                )}
                <ArrowSelect
                  value={t(order.direction)}
                  label="Směr pokynu"
                  options={codeListToArrowSelect(ORDER_DIRECTION, codeList)}
                  onChange={handleChangeArrowSelect('direction')}
                />
                <ArrowSelect
                  value={t(order.type)}
                  label="Způsob pokynu"
                  options={codeListToArrowSelect(ORDER_TYPE, codeList)}
                  onChange={handleChangeArrowSelect('type')}
                />
                {order.type === ORDER_TYPE_AMOUNT && (
                  <NumericInput
                    fullWidth={true}
                    value={order.amount || ''}
                    onChange={onClientInputChange(client)}
                    label="Objem obchodu"
                  />
                )}
                {order.type === ORDER_TYPE_QUANTITY && (
                  <NumericInput
                    fullWidth={true}
                    value={order.quantity || ''}
                    onChange={onClientInputChange(client)}
                    label="Počet kusů"
                  />
                )}
                <ArrowSelect
                  value={t(order.validityType)}
                  label="Platnost pokynu"
                  options={codeListToArrowSelect(ORDER_VALIDITY, codeList)}
                  onChange={handleChangeArrowSelect('validityType')}
                />
                {order.validityType === ORDER_VALIDITY_DATE && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      helperText={false}
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Datum"
                      value={order.validityDate || new Date()}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
                {/*}   <ArrowSelect value={textFieldValue} label="Protistrana" options={textFieldValues} /> {*/}
              </Paper>
              <Paper variant="outlined" className={classes.currencyTotal}>
                {order.type === ORDER_TYPE_AMOUNT && (
                  <>
                    <div>Počet kusů</div>
                    <div className={classes.boldText}>
                      <NumberFormat
                        value={order.quantity || 0}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />
                    </div>
                  </>
                )}
                {order.type === ORDER_TYPE_QUANTITY && (
                  <>
                    <div>Objem</div>
                    <div className={classes.boldText}>
                      <NumberFormat
                        value={order.amount || 0}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />{' '}
                      {order.currency}
                    </div>
                  </>
                )}
              </Paper>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Storno
          </Button>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleUpdate}
          >
            Hotovo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
