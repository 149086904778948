import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteOrderRequest,
  exportOrdersRequest,
  getCodeListRequest,
  getInstrumentsByParamRequest,
  getOrderRequest,
  getOrdersByStateCountRequest,
  getOrdersRequest,
  getOrderTableRowsRequest,
  getSecuritiesRequest,
  getSecurityRequest,
  importRequest,
  postMassOrderRequest,
  postOrderRequest,
  updateOrderRequest,
} from '../api/instrumentApi'
import {
  getOrderAction,
  getOrdersAction,
  getOrdersByStateCountAction,
  getOrderTableRowsAction,
  getSecuritiesAction,
  getSecurityAction,
  updateOrderAction,
} from '../redux/actions/instrumentActions'
import { Order } from '../redux/reducers/portfolio'
import { Security } from '../redux/reducers/securities'
import { CodeList } from '../types/common'
import { usePortfolioApi } from './usePortfolioApi'
import { lookup } from 'mime-types'
import {
  ACTION_FAILED_ALERT_ID,
  ACTION_SUCESS_ALERT_ID,
  FILE_IMPORT_DIALOG_ID,
  MASS_ORDER_INPUT_DIALOG_ID,
  ORDER_EDIT_DIALOG_ID,
  ORDER_INPUT_DIALOG_ID,
} from '../constants/dialogConstants'
import { closeDialog, openDialog, openDialogMessage } from '../redux/actions/dialogActions'
import { StringifyOptions } from 'querystring'
import { finishLoading, startLoading } from '../redux/actions/loadingActions'

export const useInstrumentApi = () => {
  const dispatch = useDispatch()
  const [codeList, setCodeList] = useState<CodeList[]>()
  const { getPortfolio } = usePortfolioApi()

  const getSecurity = useCallback(async (securityId: Number) => {
    await getSecurityRequest(securityId)
      .then(response => {
        console.log(response.data)
        dispatch(getSecurityAction(response.data as Security))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getSecurities = useCallback(async () => {
    await getSecuritiesRequest()
      .then(response => {
        console.log(response.data)
        dispatch(getSecuritiesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getInstrumentsByParam = useCallback(async (param: string) => {
    await getInstrumentsByParamRequest(param)
      .then(response => {
        console.log(response.data)
        dispatch(getSecuritiesAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getOrder = useCallback(async (orderId: Number) => {
    await getOrderRequest(orderId)
      .then(response => {
        console.log(response.data)
        dispatch(getOrderAction(response.data as Order))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getOrders = useCallback(async () => {
    await getOrdersRequest()
      .then(response => {
        console.log(response.data)
        dispatch(getOrdersAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getCodeList = useCallback(async () => {
    await getCodeListRequest()
      .then(response => {
        setCodeList(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const postOrder = useCallback(async (order: Order) => {
    await postOrderRequest(order, order.portfolio.portfolioId)
      .then(response => {
        getPortfolio(order.portfolio.portfolioId)
        console.log('addOrder', response.data)
        dispatch(closeDialog(ORDER_INPUT_DIALOG_ID))
        getOrdersByStateCount()
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const updateOrder = useCallback(async (order: Order) => {
    await updateOrderRequest(order)
      .then(response => {
        dispatch(updateOrderAction(response.data))
        dispatch(closeDialog(ORDER_EDIT_DIALOG_ID))
      })
      .catch(error => {
        console.log(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const postMassOrder = useCallback(async (massOrder: Order[]) => {
    await postMassOrderRequest(massOrder)
      .then(response => {
        console.log('addMassOrder', response.data)
        dispatch(closeDialog(MASS_ORDER_INPUT_DIALOG_ID))
        getOrdersByStateCount()
      })
      .catch(error => {
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const getOrdersByStateCount = useCallback(async () => {
    await getOrdersByStateCountRequest()
      .then(response => {
        console.log(response.data)
        dispatch(getOrdersByStateCountAction(response.data))
        console.log('orderCount', response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const getOrderTableRows = useCallback(async (state: string) => {
    await getOrderTableRowsRequest(state)
      .then(response => {
        console.log('sentOrderState', state)
        console.log(response.data)
        dispatch(getOrderTableRowsAction(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const exportOrders = useCallback(async () => {
    dispatch(startLoading('exportFile'))
    await exportOrdersRequest()
      .then(async response => {
        dispatch(finishLoading('exportFile'))
        const link = document.createElement('a')
        const type = lookup(response.data.name)
        console.warn(type)
        link.href = `data:${type};base64,${response.data.content}`
        link.download = response.data.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        console.log(response.data)
        getOrdersByStateCount()
      })
      .catch(error => {
        dispatch(finishLoading('exportFile'))
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
        console.error(error)
      })
  }, [])

  const importFile = useCallback(async orders => {
    dispatch(startLoading('importFile'))
    await importRequest(orders)
      .then(response => {
        if (response.data) dispatch(finishLoading('importFile'))
        dispatch(openDialogMessage(ACTION_SUCESS_ALERT_ID, response.data))
        dispatch(closeDialog(FILE_IMPORT_DIALOG_ID))
        console.log(response.data)
      })
      .catch(error => {
        dispatch(finishLoading('importFile'))
        console.error(error)
        dispatch(openDialog(ACTION_FAILED_ALERT_ID))
      })
  }, [])

  const deleteOrder = useCallback(async (order: Order) => {
    await deleteOrderRequest(order)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return {
    getSecurity,
    getSecurities,
    getInstrumentsByParam,
    getOrder,
    getOrders,
    postOrder,
    updateOrder,
    getCodeList,
    postMassOrder,
    codeList,
    getOrdersByStateCount,
    getOrderTableRows,
    exportOrders,
    importFile,
    deleteOrder,
  }
}
