import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  fillTableContainer: {
    minHeight: '60vh',
  },
})

function createData(
  fillId: string,
  amount: number,
  quantity: number,
  market: string,
  date: string,
  dealer: string,
  counterParty: string
) {
  return { fillId, amount, quantity, market, date, dealer, counterParty }
}

const rows: any = []

export const FillTable = () => {
  const classes = useStyles()

  return (
    <TableContainer component={Paper} className={classes.fillTableContainer}>
      <Table size="small" stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Fill</TableCell>
            <TableCell align="right">Objem</TableCell>
            <TableCell align="right">Kusy</TableCell>
            <TableCell align="right">Trh</TableCell>
            <TableCell align="right">Datum</TableCell>
            <TableCell align="right">Dealer</TableCell>
            <TableCell align="right">Protistrana</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow key={row.fillId}>
              <TableCell component="th" scope="row">
                {row.fillId}
              </TableCell>
              <TableCell align="right">{row.amount}</TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">{row.market}</TableCell>
              <TableCell align="right">{row.date}</TableCell>
              <TableCell align="right">{row.dealer}</TableCell>
              <TableCell align="right">{row.counterParty}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
