import axios from 'axios'

import { checkKeycloakAuthentication, keycloak } from './keycloak.service'

const USER_TOKEN_KEY = 'kc_token'
export const baseURL = process.env.REACT_APP_API

const http = axios.create({ baseURL: `${baseURL}/` })

function get<T>(url: string, headers: any = {}, params: any = {}) {
  checkKeycloakAuthentication()
  const accessToken = keycloak.token
  const authHeader = { Authorization: `Bearer ${accessToken}` }
  return http.get<T>(url, {
    ...params,
    headers: { ...authHeader, ...headers },
  })
}

function post<T>(url: string, data: any, headers: any = {}, params: any = {}) {
  checkKeycloakAuthentication()

  const accessToken = keycloak.token
  const authHeader = { Authorization: `Bearer ${accessToken}` }
  return http.post<T>(url, data, {
    ...params,
    headers: { ...authHeader, ...headers },
  })
}

function put<T>(url: string, data: any, headers: any = {}) {
  checkKeycloakAuthentication()

  const accessToken = keycloak.token
  const authHeader = { Authorization: `Bearer ${accessToken}` }
  return http.put<T>(url, data, { headers: { ...authHeader, ...headers } })
}

function remove<T>(url: string, data: any, headers: any = {}) {
  checkKeycloakAuthentication()

  const accessToken = keycloak.token
  const authHeader = { Authorization: `Bearer ${accessToken}` }
  return http.delete<T>(url, {
    headers: { ...authHeader, ...headers },
    data,
  })
}

export const fetchRequest = {
  http,
  get,
  post,
  put,
  remove,
}
