import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { ActiveOrdersTile } from './ActiveOrdersTile'
import AddIcon from '@material-ui/icons/Add'
import { openDialog } from '../../redux/actions/dialogActions'
import { MASS_ORDER_INPUT_DIALOG_ID } from '../../constants/dialogConstants'
import { useDispatch } from 'react-redux'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import { useSelector } from '../../redux/hooks/useSelector'
import { useHistory } from 'react-router'
import { Pages } from '../../router/router'
import { TransparentButton } from './TransparentButton'
import { sortOrderTable } from '../../redux/actions/filterActions'
import { OrdersByStateOptions } from '../../redux/reducers/filters'

const useStyles = makeStyles(theme => ({
  activeOrdersTiles: {
    display: 'flex',
  },
  refusedOrders: {
    color: theme.palette.error.main,
  },
  openOrders: {
    color: theme.palette.warning.main,
  },
  partialOrders: {
    color: theme.palette.success.main,
  },
  newOrder: {
    color: theme.palette.primary.main,
  },
  addMassOrderButton: {
    height: '70px',
    width: '70px',
    color: theme.palette.primary.main,
  },
  tileButton: {
    height: '100px',
    width: '160px',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    textTransform: 'none',
  },
}))

export const ActiveOrdersTiles = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { getOrdersByStateCount } = useInstrumentApi()
  const ordersByStateCount = useSelector(state => state.orders.ordersByStateCount)

  useEffect(() => {
    getOrdersByStateCount()
  }, [])

  const onTileClick = (countState: OrdersByStateOptions) => () => {
    history.push(Pages.OrderList)
    dispatch(sortOrderTable(countState))
  }

  return (
    <span className={classes.activeOrdersTiles}>
      <TransparentButton
        className={classes.tileButton}
        onClick={onTileClick(OrdersByStateOptions.Rejected)}
        content={
          <ActiveOrdersTile
            middleText={ordersByStateCount.find(count => count.state === 'OS_REJECT')?.count || 0}
            bottomText="Odmítnuté pokyny"
            className={classes.refusedOrders}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onTileClick(OrdersByStateOptions.Open)}
        content={
          <ActiveOrdersTile
            middleText={ordersByStateCount.find(count => count.state === 'OS_SENT')?.count || 0}
            bottomText="Otevřené pokyny"
            className={classes.openOrders}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onTileClick(OrdersByStateOptions.Partial)}
        content={
          <ActiveOrdersTile
            middleText={ordersByStateCount.find(count => count.state === 'OS_PARTIAL_SETTLED')?.count || 0}
            bottomText="Částečné pokyny"
            className={classes.partialOrders}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={onTileClick(OrdersByStateOptions.New)}
        content={
          <ActiveOrdersTile
            middleText={ordersByStateCount.find(count => count.state === 'OS_NEW')?.count || 0}
            bottomText="Neodeslané pokyny"
            className={classes.partialOrders}
          />
        }
      />
      <TransparentButton
        className={classes.tileButton}
        onClick={() => {
          dispatch(openDialog(MASS_ORDER_INPUT_DIALOG_ID))
        }}
        content={
          <ActiveOrdersTile
            middleText={<AddIcon fontSize="large" />}
            bottomText="Nový hromadný pokyn"
            className={classes.newOrder}
          />
        }
      />
    </span>
  )
}
