import { ReduxAction } from '../../types/common'
import {
  FILTER_AMOUNT_DOWN,
  FILTER_AMOUNT_UP,
  FILTER_CLIENT_BY_INSTRUMENT_NAME,
  FILTER_CLIENT_NAME,
  FILTER_CURRENCY_NAME,
  FILTER_INSTRUMENT_NAME,
  FILTER_MARKET_NAME,
  FILTER_PORTFOLIO_DETAIL,
  ORDER_CLIENTS,
  SORT_ORDERS,
} from '../actionTypes'

export enum ClientSortOptions {
  Az,
  Za,
  AmountUp,
  AmountDown,
}

export enum OrdersByStateOptions {
  New,
  Open,
  Partial,
  Rejected,
  Closed,
  All,
}

export interface FiltersState {
  sortOrdersBy: OrdersByStateOptions
  searchedClientName: string
  sortBy: ClientSortOptions
  portfolioDetail: {
    class: boolean
    instrumentType: boolean
    currency: boolean
  }
  searchedInstrumentName: string
  searchedMarketName: string
  searchedAmountUp: string
  searchedAmountDown: string | number
  searchedClientByInstrumentName: string
  searchedCurrencyName: string
}

const INITIAL_STATE: FiltersState = {
  sortOrdersBy: OrdersByStateOptions.All,
  searchedClientName: '',
  sortBy: ClientSortOptions.Az,
  portfolioDetail: {
    class: false,
    instrumentType: true,
    currency: false,
  },
  searchedInstrumentName: '',
  searchedMarketName: '',
  searchedAmountUp: '',
  searchedAmountDown: '',
  searchedClientByInstrumentName: '',
  searchedCurrencyName: '',
}

export const filters = (state: FiltersState = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {
    case FILTER_CLIENT_NAME:
      return {
        ...state,
        searchedClientName: action.payload,
      }
    case ORDER_CLIENTS:
      return {
        ...state,
        sortBy: action.payload,
      }
    case FILTER_PORTFOLIO_DETAIL:
      const { name, value } = action.payload
      return {
        ...state,
        portfolioDetail: {
          class: false,
          instrumentType: false,
          currency: false,
          [name]: value,
        },
      }
    case FILTER_INSTRUMENT_NAME:
      return {
        ...state,
        searchedInstrumentName: action.payload,
      }
    case FILTER_MARKET_NAME:
      return {
        ...state,
        searchedMarketName: action.payload,
      }
    case FILTER_AMOUNT_UP:
      return {
        ...state,
        searchedAmountUp: action.payload,
      }
    case FILTER_AMOUNT_DOWN:
      return {
        ...state,
        searchedAmountDown: action.payload,
      }
    case SORT_ORDERS:
      return {
        ...state,
        sortOrdersBy: action.payload,
      }
    case FILTER_CLIENT_BY_INSTRUMENT_NAME:
      return {
        ...state,
        searchedClientByInstrumentName: action.payload,
      }
    case FILTER_CURRENCY_NAME:
      return {
        ...state,
        searchedCurrencyName: action.payload,
      }
    default:
      return state
  }
}
