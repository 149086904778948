import React, { useLayoutEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { ORDER_DETAIL_DIALOG_ID, ORDER_EDIT_DIALOG_ID } from '../../constants/dialogConstants'
import { useDispatch } from 'react-redux'
import { closeDialog, openDialog } from '../../redux/actions/dialogActions'
import { useSelector } from '../../redux/hooks/useSelector'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { InstrumentIcon } from '../common/InstrumentIcon'
import { useInstrumentIconType } from '../../hooks/useInstrumentIconType'
import manS from '../../assets/manS.jpg'
import { FillTable } from '../OrderDetailDialog.tsx/FillTable'
import { useEffect } from 'react'
import { useClientApi } from '../../hooks/useClientApi'
import { useInstrumentApi } from '../../hooks/useInstrumentApi'
import NumberFormat from 'react-number-format'
import { useInputDialogColor } from '../../hooks/useInputDialogColor'
import { isSetAccessorDeclaration } from 'typescript'
import { emptyClient, newOrder } from '../../constants/initialStates'
import { Order } from '../../redux/reducers/portfolio'
import { Client } from '../../redux/reducers/clients'
import { Security } from '../../redux/reducers/securities'
import { getClientAction } from '../../redux/actions/clientActions'
import { getOrderAction, getSecurityAction } from '../../redux/actions/instrumentActions'
import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  dialogWindow: {},
  DialogHeader: {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  commodityContainer: {
    height: '100px',
    width: '550px',
    display: 'flex',
    flexDirection: 'column',
  },
  topContainer: {
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'row',
  },
  iconSize: {
    height: '25px',
    width: '45px',
    lineHeight: '25px',
    fontSize: '12px',
  },
  position: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  topLineText: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  boldText: {
    fontWeight: 'bold',
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  topLineSeparator: {
    flexGrow: 1,
  },
  topLineIsin: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    width: '100%',
    height: '50%',
    alignItems: 'flex-end',
  },
  securityInfo: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  percentage: {
    color: theme.palette.success.main,
  },
  clientContainer: {
    height: '100px',
    width: '550px',
    display: 'flex',
    flexDirection: 'row',
  },
  clientPersonalInfoContainer: {
    height: '100%',
    width: '50%',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  clientIcon: {
    height: '40px',
    width: '40px',
  },
  clientCredentials: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  clientPhone: {
    marginTop: theme.spacing(1),
  },
  clientPortfolioContainer: {
    height: '100%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  fulfillContainer: {
    height: '120px',
    width: '550px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  actionButtons: {
    width: '15%',
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
  },
  editButton: {
    backgroundColor: theme.palette.warning.main,
  },
  leftCellWidth: {
    width: '40%',
  },
  rightCellWidth: {
    width: '55%',
  },
  textEnd: {
    textAlign: 'end',
  },
  rowOrder: {
    display: 'flex',
    flexDirection: 'row',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginTopSmall: {
    marginTop: theme.spacing(0.5),
  },
  isin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

export const OrderDetailDialog = () => {
  const dispatch = useDispatch()
  const { getClient } = useClientApi()
  const { getSecurity, getOrder, deleteOrder } = useInstrumentApi()
  const classes = useStyles()
  const dialogs = useSelector(state => state.dialogs.dialogs)
  const foundDialog = dialogs.find(dialog => dialog.id === 'ORDER_DETAIL_DIALOG_ID')
  const open = !!foundDialog
  const security = useSelector(state => state.securities.security)
  const instrumentIconType = useInstrumentIconType(!security ? null : security?.assetClass)
  const client = useSelector(state => state.clients.client)
  const order = useSelector(state => state.orders.order)
  const getInputDialogColor = useInputDialogColor()
  const { t } = useTranslation()

  useEffect(() => {
    console.log('loadOrderDetailDialog')
  }, [])

  useEffect(() => {
    if (foundDialog) {
      getClient(foundDialog?.payload.clientId)
      getSecurity(foundDialog?.payload.securityId)
      getOrder(foundDialog?.payload.orderId)
      console.log('getClientCalledOnOrderDetailDialog')
    }
  }, [foundDialog])

  useLayoutEffect(() => {
    return () => {
      dispatch(closeDialog(ORDER_DETAIL_DIALOG_ID))
      dispatch(getClientAction(emptyClient))
      dispatch(getOrderAction(newOrder))
      dispatch(getSecurityAction(undefined))
      console.log('closeOrderDetailOnLocationChangeFired')
    }
  }, [])

  const handleClose = () => {
    dispatch(closeDialog(ORDER_DETAIL_DIALOG_ID))
    dispatch(getClientAction(emptyClient))
    dispatch(getOrderAction(newOrder))
    dispatch(getSecurityAction(undefined))
  }

  const handleEdit = () => {
    dispatch(openDialog(ORDER_EDIT_DIALOG_ID))
    dispatch(closeDialog(ORDER_DETAIL_DIALOG_ID))
  }

  const handleDelete = () => {
    deleteOrder(order)
    dispatch(closeDialog(ORDER_DETAIL_DIALOG_ID))
    dispatch(getClientAction(emptyClient))
    dispatch(getOrderAction(newOrder))
    dispatch(getSecurityAction(undefined))
  }

  const isEditDisabled = () => {
    if (order.state !== 'OS_NEW') return true
  }

  if (!security) return null

  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={`${classes.dialogWindow} `}
      >
        <div className={`${classes.DialogHeader} ${getInputDialogColor(order.direction)}`}>
          <Typography variant="h5">Detail Pokynu {order.orderIdent}</Typography>
        </div>
        <DialogContent>
          <div className={classes.dialogContentContainer}>
            <div>
              <FillTable />
            </div>
            <div>
              <Paper className={`${classes.commodityContainer} ${classes.position}`}>
                <div className={classes.topContainer}>
                  <InstrumentIcon
                    className={`${classes.iconSize} ${instrumentIconType} ${classes.position}`}
                    securityType={security.assetClass}
                  />
                  <span className={`${classes.topLineText} ${classes.boldText} ${classes.textColor}`}>
                    {security.name}
                  </span>
                  <div className={classes.topLineSeparator} />
                  <div className={classes.isin}>
                    <span className={` ${classes.boldText} ${classes.textColor}`}>{security.isin}</span>
                    <span className={` ${classes.textColor}`}>{t(security.instrumentType)}</span>
                  </div>
                </div>
                <div className={classes.bottomContainer}>
                  <span className={classes.securityInfo}>
                    {security.lastQuoteDate}{' '}
                    <span className={classes.boldText}>
                      <NumberFormat
                        value={security.lastQuotePrice}
                        displayType="text"
                        thousandSeparator=" "
                        decimalScale={8}
                        decimalSeparator=","
                      />
                    </span>{' '}
                    {security.currency} <span className={classes.percentage}>{security.lastPriceIndication}</span>
                  </span>
                  <div className={classes.topLineSeparator} />
                  <span className={classes.securityInfo}>{security.market}</span>
                </div>
              </Paper>
              <Paper className={`${classes.clientContainer} ${classes.position}`}>
                <div className={classes.clientPersonalInfoContainer}>
                  <img alt="" className={classes.clientIcon} src={manS} width={20} />
                  <div className={classes.clientCredentials}>
                    <span>
                      {client?.firstName} <span className={classes.boldText}>{client?.surname}</span>
                    </span>
                    <span className={classes.clientPhone}>
                      <NumberFormat value={client.telephoneNumber} displayType={'text'} format="##### ### ### ###" />
                    </span>
                  </div>
                </div>
                <div className={classes.clientPortfolioContainer}></div>
              </Paper>
              <Paper className={classes.fulfillContainer}>
                <Grid container spacing={0} className={`${classes.marginLeft} ${classes.marginTopSmall}`}>
                  <Grid item xs={6} className={classes.rowOrder}>
                    <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Typ pokynu:</Typography>{' '}
                    <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}>{t(order.type)}</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.rowOrder}>
                    <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Datum založení:</Typography>{' '}
                    <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}>
                      {order.orderCreationDate ? format(new Date(order.orderCreationDate), 'dd/MM/yyyy HH:mm') : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.rowOrder}>
                    <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Objem pokynu:</Typography>{' '}
                    <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}>
                      <NumberFormat
                        value={order.amount}
                        displayType="text"
                        thousandSeparator=" "
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                      />{' '}
                      {order.currency}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.rowOrder}>
                    <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Datum podání:</Typography>{' '}
                    <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}></Typography>
                  </Grid>

                  <Grid item xs={6} className={classes.rowOrder}>
                    <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Počet kusů:</Typography>{' '}
                    <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}>
                      <NumberFormat
                        value={order.quantity}
                        displayType="text"
                        thousandSeparator=" "
                        decimalScale={4}
                        decimalSeparator=","
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={6} className={classes.rowOrder}>
                    <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Typ validity:</Typography>{' '}
                    <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}>
                      {t(order.validityType)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.rowOrder}>
                    <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Směr Pokynu:</Typography>{' '}
                    <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}>
                      {t(order.direction)}
                    </Typography>
                  </Grid>
                  {order.validityType === 'OV_GTD' && (
                    <Grid item xs={6} className={classes.rowOrder}>
                      <Typography className={`${classes.leftCellWidth} ${classes.textColor}`}>Platný do:</Typography>{' '}
                      <Typography className={`${classes.textEnd} ${classes.rightCellWidth}`}>
                        {order.validityDate ? format(new Date(order.validityDate), 'dd/MM/yyyy') : ''}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={`${classes.actionButtons} ${classes.cancelButton}`}
            disabled={isEditDisabled()}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleDelete}
          >
            Zrušit pokyn
          </Button>
          <Button
            disabled={isEditDisabled()}
            className={`${classes.actionButtons} ${classes.editButton}`}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleEdit}
          >
            Upravit pokyn
          </Button>
          <Button
            className={classes.actionButtons}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Zavřít dialog
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
